<template>
  <div>
    <section id="page-title" class="page-title-parallax page-title-center page-title-dark include-header" style="background-image: linear-gradient(to top, rgba(254,150,3,0.5), #39384D), url('/assets/images/services/banner/technicalsupport.jpg'); background-size: cover; padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">
        <div id="particles-line"></div>

        <div class="container clearfix mt-4">
            <div class="badge badge-pill border border-light text-light">Technical Support Success</div>
            <h1>Technical Support Success</h1>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Technical Support Success</li>
            </ol>
        </div>

    </section>
    <section id="content">

			<div class="content-wrap pb-0">
                <!-- Main Content
				============================================= -->
                <div class="container">
					<div class="row align-items-end">
						<div class="col-lg-6">
							<div class="badge badge-pill badge-default">Technical Support Success</div>
							<h2 class="display-4 font-weight-bold">Empowering Technical Support Specialists.</h2>
							<p>
                                eFlex productively manages inquiries received from your partners regarding any technical challenge they may be experiencing. We make sure  to resolve queries, recommend solutions, and guide product users while demonstrating flexibility and adaptability.
                            </p>
							<p>

                            </p>
							<router-link to="/contact"><a class="button button-rounded button-large nott ls0">Get Started</a></router-link>
						</div>
						<div class="col-lg-6 mt-4 mt-lg-0">
							<img src="/assets/images/services/header/technicalsupport.jpg" alt="Image">
						</div>
					</div>
				</div>
				<!-- Features
				============================================= -->
				<div class="section mb-0">
					<div class="container">
						<div class="heading-block border-bottom-0 center">
							<div class="badge badge-pill badge-default">Services</div>
							<h3 class="nott ls0">What We Can Do</h3>
							<p>At eFlex, we understand that technical support takes a multi-disciplinary team to get it right. Our agents have detailed knowledge of your service to guarantee first-rate assistance for any technical or UX issues. Being customer-centric, our agents are adept at communicating and working with a variety of people and departments.</p>
						</div>
						<div class="row col-mb-30 align-content-stretch">

							<div class="col-lg-4 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/pagecards/technicalsupport1.jpg" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">Technical Expertise</h3>
												<p>
                                                    Our technical support agents have excellent problem-solving and analytical skills essential in process improvement and maintaining system functionality. We pay attention to details in managing process improvements and operating systems, redirecting tech problems to appropriate resources, and explaining technical information clearly
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-4 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/pagecards/technicalsupport2.jpg" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">IT / Computer Science Background</h3>
												<p>
                                                    All of our agents have associate or bachelor’s degrees in information technology, computer science, or other related fields. eFlex also ensures that each team member is given targeted training based on the particular business needs of our partners. Through continued education, up skilling, quality assurance, and coaching, they can be a resource to lean on when partners are in need of guidance.
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-4 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/pagecards/technicalsupport3.jpg" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">API Experience</h3>
												<p>
                                                    We can provide immediate, clear, and effective responses to customers about the features, functionality, and implementation of our partners’ Application Programming Interface (API). Our technical teams are capable of learning, understanding, and speaking to your customers about your API.
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
				<Contact/>
			</div>
	</section>
  </div>
</template>

<script>
// @ is an alias to /src
import Contact from '@/components/ContactUs.vue'

export default {
	name: 'Technical',
	data() {
		return {
			
		}
	},
	components: {
		Contact
	},
	computed: {
		
	},
	methods: {
		
	},
	mounted() {
		window.scrollTo(0, 0)
	}
}
</script>
